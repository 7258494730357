<script setup lang="ts">
import { CartTotals } from '~/lib/services/store/catalog/cart.dtos'

const { t } = useLocale()
const { format } = useFormatAmount()
const props = defineProps<{ prices: CartTotals; isVisible: boolean }>()

const isVisible = computed(() => props.isVisible)
</script>
<template>
  <div>
    <transition name="slide">
      <div
        v-if="isVisible"
        class="fixed bottom-16 left-0 flex w-full justify-between rounded-3xl bg-white p-5 py-6 text-2xl font-bold shadow-elevation-up"
      >
        <p>{{ t('shoppingCart.total') }}</p>
        <span>{{ format(prices.total) }}</span>
      </div>
    </transition>
  </div>
</template>

<style scoped>
.slide-enter-active,
.slide-leave-active {
  @apply transition-all duration-500 ease-in;
}

.slide-enter-from,
.slide-leave-to {
  @apply translate-y-full opacity-0;
}
</style>
