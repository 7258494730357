import { YunoSVGIcons, YunoAnalyticsPaymentType } from '~/lib/models/Yuno'

export const yunoIconPath = ({
  brand,
  type = '',
  icon,
}: {
  brand?: string
  type: string
  icon?: string
}) => {
  if (!brand && !type) return icon || `/images/icons/checkout/card.svg`
  const key = brand || type
  if (!YunoSVGIcons[key]) return icon || `/images/icons/checkout/card.svg`
  const iconPath = `/images/icons/checkout/cards/${YunoSVGIcons[key]}.svg`
  return iconPath
}

export const paymentMethodToSend = (paymentMethodType: string) => {
  const key = YunoAnalyticsPaymentType[paymentMethodType]
  if (key) return key
  return `${paymentMethodType.toLowerCase()}`
}
