<script setup lang="ts">
import type { ISetTip } from '~/lib/models/events'
import { TipOption, TipType } from '~/lib/services/store/country/country.dtos'

const props = defineProps<{
  option: TipOption
  index: number
  isSelected: boolean
}>()

const emit = defineEmits<{
  (e: 'set-tip', payload: ISetTip): void
}>()

const { format } = useFormatAmount()

const amountShowed = computed(() => {
  if (props.option.type === TipType.PERCENT) return `${props.option.amount} %`
  return format(props.option.amount)
})

const clickTip = () => {
  const isSelected = props.isSelected
  emit('set-tip', { option: isSelected ? undefined : props.option, index: props.index })
}
</script>

<template>
  <div
    data-test="tip__chip"
    class="h-8 cursor-pointer whitespace-nowrap rounded-[100px] border border-mcd-black bg-white px-4 py-[6px] text-xs"
    :class="{ '!border-mcd-main-accent !bg-mcd-main font-bold': isSelected }"
    @click="clickTip"
  >
    {{ amountShowed }}
  </div>
</template>
