<script lang="ts" setup>
import { storeToRefs } from 'pinia'
import { StoreOrderStatus } from '~/lib/services/store/order/order.dto'
import { CUSTOM_ERRORS_TYPES, CUSTOM_IM_ANALYTICS_ERRORS } from '~/lib/models/gtm'
const { t } = useLocale()
const appStore = useAppStore()
const restaurantStore = useRestaurantStore()
const { restaurant } = storeToRefs(restaurantStore)
const { trackTooFar, trackCustomError } = useGTM()

const text = ref('')

const checkRemotenessAlert = async () => {
  if (!restaurant?.value) return
  const userRemotenessConfig = appStore.ecommerceConfig?.displayRestaurantConfirmation
  const { latitude: lat, longitude: lng } =
    appStore.currentOrder?.deliveryPlace || restaurant.value.coordinates
  const { isUserFarAway: userFarFromRestaurant } = await isUserFarFromCoords({
    coordinates: {
      lat,
      lng,
    },
    maxDistance: userRemotenessConfig || 100,
  })
  if (userFarFromRestaurant) {
    const orderStatus = appStore.currentOrder?.status
    text.value =
      orderStatus === StoreOrderStatus.PAYMENT_PENDING
        ? t(`restaurantTooltipAlert.farFromRestaurantWithOrder`)
        : t(`restaurantTooltipAlert.farFromRestaurantWithoutOrder`)
    // Event custom_error
    trackCustomError({
      code: `${CUSTOM_IM_ANALYTICS_ERRORS.TOO_FAR}`,
      type: CUSTOM_ERRORS_TYPES.TOO_FAR,
    })
    // Event too_far
    trackTooFar()
  }
}

onMounted(async () => {
  checkRemotenessAlert()
})
</script>
<template>
  <div
    v-if="text"
    class="flex items-center gap-2 rounded-md bg-mcd-secondaryLightGrey py-4 pl-4 pr-2"
  >
    <img src="/images/icons/warning-alert.svg" alt="warning" />
    <section class="flex flex-col">
      <p class="text-sm">{{ text }}</p>
    </section>
  </div>
</template>
