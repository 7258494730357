<script setup lang="ts">
import type { ISetTip } from '~/lib/models/events'
import { ToastType } from '~/lib/models/ui/toast'
import { TipOption, TipType } from '~/lib/services/store/country/country.dtos'

const CustomTipState = {
  DEFAULT: 'default',
  EDITING: 'editing',
  FINISHED_EDITING: 'finished_editing',
} as const
export type CustomTipStateType = (typeof CustomTipState)[keyof typeof CustomTipState]

const props = defineProps<{
  option: TipOption
  index: number
  isSelected: boolean
  customAmount?: number
}>()

const emit = defineEmits<{
  (e: 'set-tip', payload: ISetTip): void
}>()

const { t } = useLocale()
const { format, getCurrencySymbol } = useFormatAmount()
const { $toast } = useNuxtApp()

const amount = ref<number>()
const state = ref<CustomTipStateType>(CustomTipState.DEFAULT)
const input = ref<HTMLInputElement>()
const maxAmount = props.option.amount || undefined

const isPercent = computed(() => props.option.type === TipType.PERCENT)
const isDefault = computed(() => state.value === CustomTipState.DEFAULT)
const isEditing = computed(() => state.value === CustomTipState.EDITING)
const isFinishedEditing = computed(() => state.value === CustomTipState.FINISHED_EDITING)
const amountShowed = computed(() => {
  if (isPercent.value) return `${amount.value} %`
  return format(amount.value || 0)
})

const startEditing = () => {
  state.value = CustomTipState.EDITING
  amount.value = undefined
  setTimeout(() => {
    if (input.value) input.value.focus()
  }, 0)
}

const onKeyDown = (ev: any) => {
  const invalidChars = ['-', '+', 'e', '.', ',']
  if (invalidChars.includes(ev.key)) ev.preventDefault()
}

const onChangeInput = (ev: any) => {
  amount.value = ev.target.value
  setTimeout(() => {
    if (amount.value && input.value) {
      const width = `${amount.value.toString().length + 2}ch`
      input.value.style.width = width
    }
  }, 0)
}

const onFinishWriting = () => {
  let isMaxError = false
  if (amount.value) {
    if (isPercent.value) {
      if (amount.value <= 0) amount.value = 1
      if (maxAmount && amount.value > maxAmount) {
        isMaxError = true
      }
    } else {
      const qty = amount.value
      amount.value = qty <= 0 ? 100 : qty * 100
    }

    // is Percent and amount is over max
    if (isMaxError) {
      state.value = CustomTipState.EDITING
      // Reset custom selected if any
      emitEvent(undefined)
      $toast.show({
        type: ToastType.WARNING,
        content: t('checkout.sections.tip.toast.max', { max: maxAmount }),
      })
      input.value?.focus()
    } else {
      const eventToSend = { option: props.option, index: props.index, amount: amount.value }
      emitEvent(eventToSend)
      state.value = CustomTipState.FINISHED_EDITING
    }
  } else {
    // is user is typing but he left empty input
    emitEvent(undefined)
    state.value = CustomTipState.DEFAULT
  }
}

// select this custom tip chip when has value
const selectChipClick = () => {
  const isPercentOverMax = !isPercent.value
    ? false
    : !!(amount.value && maxAmount && amount.value > maxAmount)
  if (amount.value && !isPercentOverMax) {
    emitEvent(
      props.isSelected
        ? undefined
        : {
            option: props.isSelected ? undefined : props.option,
            index: props.index,
            amount: amount.value,
          }
    )
  }
}

const emitEvent = (ev: ISetTip | undefined) => {
  if (!ev) emit('set-tip', { option: undefined, index: props.index })
  else emit('set-tip', ev)
}

onMounted(() => {
  if (props.customAmount) {
    amount.value = props.customAmount
    state.value = CustomTipState.FINISHED_EDITING
  }
})
</script>

<template>
  <div
    data-test="tip__chip--custom"
    class="flex h-8 cursor-pointer flex-row items-center justify-center whitespace-nowrap rounded-[100px] border border-mcd-black bg-white px-4 py-[6px] text-xs"
    :class="{ '!border-mcd-main-accent !bg-mcd-main font-bold': isSelected }"
    @click="selectChipClick"
  >
    <!-- Default state -->
    <p v-if="isDefault" class="font-bold">
      {{ t(`checkout.sections.tip.chips.custom_${option.type}`) }}
    </p>

    <!-- Symbol when editing amount -->
    <p v-if="!isPercent && isEditing">{{ getCurrencySymbol() }}</p>

    <!-- Input where user add custom tip -->
    <input
      v-if="isEditing"
      ref="input"
      type="number"
      class="block h-full w-[2ch] border-none text-center caret-black outline-none"
      :class="{ 'bg-mcd-main': isSelected }"
      inputmode="numeric"
      @input="onChangeInput"
      @keydown="onKeyDown"
      @keydown.enter="input?.blur()"
      @blur="onFinishWriting"
    />

    <!-- % when editing percentage -->
    <p v-if="isPercent && isEditing">%</p>

    <!-- Finished edition: Tip applied -->
    <p v-if="isFinishedEditing">
      {{ amountShowed }}
    </p>

    <!-- startEditing image -->
    <img
      class="ml-2 h-3 w-3"
      src="/images/icons/edit.svg"
      alt="Edit custom tip"
      @click.self.stop="startEditing"
    />
  </div>
</template>

<style scoped>
/* Hide input:number arrows */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  appearance: textfield;
  -moz-appearance: textfield;
}
input:hover {
  border: none;
}
input:focus {
  outline: none;
}
</style>
