import loadScript from 'load-script'
import { publicConfig } from '~/config/public'
import {
  YunoEnrollmentErrorType,
  YunoEnrollmentSDKResponse,
  YunoEnrollmentStatusType,
  YunoPaymentSDKResponse,
  YunoPaymentStatus,
  YunoPaymentSubStatus,
  yunoSDKCustomizeStyles,
} from '~/lib/models/Yuno'
import { YunoMethod } from '~/lib/services/store/payment/payment.dtos'
import { isCypressSpecialBehaviour } from '~/utils/uiUtils'

declare global {
  interface Window {
    Yuno: any
  }
}

export const useYuno = () => {
  let yuno: any

  const initYuno = async () => {
    try {
      const publicApiKey = publicConfig.sdk.yuno.publicApiKey
      if (yuno) return yuno
      else if (window.Yuno) {
        yuno = window.Yuno.initialize(publicApiKey)
        return yuno
      }

      const isCypress = isCypressSpecialBehaviour()
      const yunoMock = !isCypress
        ? undefined
        : ((await import('~/tests/e2e/cypress/support/mocks/yuno')) as any).default

      await new Promise<void>((resolve, reject) => {
        // cypressSpecialBehaviour
        if (isCypress) {
          resolve()
        } else {
          // Load yuno SDK library
          const scriptUrl = 'https://sdk-web.y.uno/v1/static/js/main.min.js'
          loadScript(scriptUrl, (err: any) => {
            if (err) return reject(err)
            resolve()
          })
        }
      })

      if (isCypress) yuno = yunoMock.initialize()
      else yuno = window.Yuno.initialize(publicApiKey)

      return yuno
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('🚀 ~ file: useYuno.ts:28 ~ initYuno ~ error:', error)
    }
  }

  const enrollment = async (customerSession: string, language: string) => {
    if (!yuno) await initYuno()
    const countryCode = useCountry()

    return await new Promise<YunoEnrollmentSDKResponse>(resolve => {
      yuno.mountEnrollmentLite({
        customerSession,
        countryCode,
        language,
        showLoading: true,
        renderMode: {
          type: 'modal',
        },
        card: {
          type: 'step',
          styles: yunoSDKCustomizeStyles,
          cardSaveEnable: false,
          texts: {},
          documentEnable: true,
        },
        // on finish enrollment
        yunoEnrollmentStatus: ({
          status,
          vaultedToken,
        }: {
          status: YunoEnrollmentStatusType
          vaultedToken: string
        }) => {
          resolve({
            status,
            vaultedToken,
            error: undefined,
          })
        },
        // error inside sdk CANCELED_BY_USER | any
        yunoError: (error: YunoEnrollmentErrorType) => {
          resolve({
            status: 'ERROR',
            vaultedToken: undefined,
            error,
          })
        },
      })
    })
  }

  const payment = async (
    method: YunoMethod,
    checkoutSession: string,
    language: string,
    createPayment: Function
  ) => {
    if (!yuno) await initYuno()
    const countryCode = useCountry()

    return await new Promise<YunoPaymentSDKResponse>(resolve => {
      // startCheckout
      yuno.startCheckout({
        checkoutSession,
        countryCode,
        language,
        showLoading: true,
        elementSelector: '#yuno-checkout',
        renderMode: {
          type: 'modal',
        },
        card: {
          type: 'step',
          styles: yunoSDKCustomizeStyles,
          cardSaveEnable: false,
          texts: {},
          documentEnable: true,
        },
        async yunoCreatePayment(oneTimeToken: string) {
          const { status, subStatus, checkout, httpStatusCode } = await createPayment({
            oneTimeToken,
            checkoutSession,
          })
          const sdkRequiredAction = checkout ? checkout.sdkRequiredAction : false
          if (sdkRequiredAction) yuno.continuePayment()
          resolve({
            status,
            subStatus,
            sdkRequiredAction,
            error: undefined,
            httpStatusCode,
          })
        },
        // error inside sdk CANCELED_BY_USER | any
        yunoError: (error: YunoEnrollmentErrorType) => {
          resolve({
            status: YunoPaymentStatus.ERROR,
            subStatus: YunoPaymentSubStatus.ERROR,
            sdkRequiredAction: false,
            error,
            httpStatusCode: 500,
          })
        },
      })

      // mountCheckoutLite
      yuno.mountCheckoutLite({
        paymentMethodType: method.type,
        vaultedToken: method.vaultedToken || undefined,
      })
    })
  }

  return { initYuno, enrollment, payment }
}
